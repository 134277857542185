import React from "react"
import { graphql } from "gatsby"
import { Biography, Layout, SEO } from "../components"

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
//   const introPicture = data.IntroPicture.childImageSharp.fluid
  const secondPicture = data.SecondPicture.childImageSharp.fluid

  return (
    <Layout title={siteTitle}>
      <SEO title="Biography" />
      {/* <Intro bg="bg-tertiary" svgFill="tertiary" picture={introPicture}/> */}
      <Biography picture={secondPicture} />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    IntroPicture: file(absolutePath: { regex: "/CC-concert-square.jpg/" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    SecondPicture: file(absolutePath: { regex: "/MLK-concert.jpg/" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
